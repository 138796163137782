.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 9999999
}

.content {
    background-color: var(--background-color);
    border-radius: 16px 16px 0 0;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    max-height: 80vh;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 99999999
}

.title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
    overflow-x: auto;
    width: calc(100% + 2rem);
    margin-left: -2rem;
    padding: 0 1rem;
}

.buttonWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 105px;
    width: 75px;
}

.buttonWrap span {
    font-size: 11px;
    margin-top: 0.5rem;
}

.shareNetworkButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0.75rem;
    border: none;
    border-radius: 50%;
    width: 51px;
    height: 51px;
    font-size: 0.875rem;
    transition: background-color 0.2s;
    color: var(--text-color);
    background-color: var(--thirdary-color);
}

.shareNetworkButton:hover {
    background-color: var(--branding-purple);
    color: var(--white-text);
}

.icon {
    width: 20px;
    height: 20px;
}

.productInfo {
    background-color: var(--secondary-color);
    padding: 1rem;
    border-radius: 8px;
}

.productName {
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.productPrice {
    color: #6b7280;
    font-size: 0.875rem;
}

.closeButton {
    position: absolute;
    top: 6px;
    right: 6px;
    background: var(--background-color);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    color: var(--text-color);
    opacity: 1;
    transition: opacity 0.2s ease;
    z-index: 1;
    height: 36px;
    width: 36px;
    display: flex;
    box-shadow: 0 0 3px #fff;
    justify-content: center;
    align-items: center;
}

.closeButton:hover {
    background: var(--branding-purple);
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
