.themeToggleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 30px;
    padding: 5px;
    width: 100%;
    max-width: 118px;
    flex-wrap: wrap;
}
.labels {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
}
.label {
    color: var(--text-color);
    font-size: 14px;
    padding: 0 10px;
}

.label.active {
    color: var(--text-color);
}

.switch {
    position: relative;
    display: inline-block;
    width: 130px;
    height: 51px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eee;
    transition: .4s;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.slider:before {
    position: absolute;
    content: "";
    height: 51px;
    width: 63px;
    left: 0;
    bottom: 0;
    background-color: var(--branding-purple);
    transition: .4s;
    border-radius: 9px;
}

input:checked + .slider:before {
    transform: translateX(55px);
}

.darkIcon {
    color: var(--black-text);
    font-size: 24px;
    z-index: 1;
    padding: 0 18px;
}

.icon {
    color: var(--white-text);
    font-size: 24px;
    z-index: 1;
    padding: 0 18px;
}
