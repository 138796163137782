.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px 20px 120px;
}

.searchSection {
    margin-bottom: 20px;
}

.searchSection input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.resultsList {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 9px;
}

.resultItem {
    padding: 10px;
    cursor: pointer;
}

.resultItem:hover {
    background-color: #f0f0f0;
}

.resultItem.selected {
    background-color: var(--branding-purple);
}

.selectionInfo {
    margin: 20px 0;
}

.roleSelect {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: var(--branding-purple);
    color: var(--white-text)
}

.submitButton {
    width: 100%;
    padding: 10px;
    background-color: var(--branding-purple);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 9px;
}

.submitButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}


.connectedUsers {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
}

.userList {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 9px;
    justify-content: space-between;
}

.userItem {
    background-color: var(--secondary-color);
    border: none;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    height: 150px;
    width: 150px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.userImageWrap {
    width: 60px;
    height: 60px;
    border-radius: 9px;
    overflow: hidden;
    margin-bottom: 6px;
}
.profilePhotoPlaceholder {
    background-color: #f0f0f0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black-text);
}
.userName {
    font-weight: bold;
    color: var(--text-color);
}

.userEmail {
    color: var(--text-color);
    font-size: 9px;

}

.userRoles {
    font-size: 10px;
    text-align: center;
    margin-top: 6px;
}

.userRole {
    font-style: italic;
    color: var(--text-color);

}
