/* InstagramStyleSearch.module.css */
.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem 1rem 200px;
    position: relative;
    z-index: 2;
}
.historyItem {
    background-color: transparent;
    color: var(--text-color);
    border: none;
}

.searchInputWrapper {
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.searchInputContainer {
    width: 100%;
    position: relative;
}

.searchInputContainer .searchIndicator {
    position: absolute;
    left: 9px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.searchButton {
    width: 69px;
    height: 75%;
    position: absolute;
    right: 6px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: auto;
    border-radius: 27px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    flex-direction: column;
    cursor: pointer;

    /* Pearlescent effect */
    background: var(--branding-purple);
    box-shadow:
        0 2px 4px rgba(119, 84, 150, 0.3),
        0 4px 8px rgba(119, 84, 150, 0.2),
        0 -1px 2px rgba(255, 255, 255, 0.2) inset,
        0 2px 4px rgba(0, 0, 0, 0.1) inset;
    transition: all 0.3s ease;
}

.searchButton:hover {
    background: linear-gradient(145deg, #8a62b0, #775496);
    box-shadow:
        0 4px 8px rgba(119, 84, 150, 0.4),
        0 6px 12px rgba(119, 84, 150, 0.3),
        0 -1px 2px rgba(255, 255, 255, 0.3) inset,
        0 2px 4px rgba(0, 0, 0, 0.2) inset;
}

.searchButton:hover svg {
    stroke: var(--white-text);
}

.searchButton:active {
    background: linear-gradient(145deg, #6a4b87, #7d5aa0);
    box-shadow:
        0 1px 2px rgba(119, 84, 150, 0.3),
        0 2px 4px rgba(119, 84, 150, 0.2),
        0 -1px 2px rgba(255, 255, 255, 0.1) inset,
        0 1px 2px rgba(0, 0, 0, 0.2) inset;
    transform: translateY(1px);
}

.searchInputContainer svg {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: none;
    color: #fff;
}

.backLink, .backButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.backLink svg, .backButton svg {
    font-size: 2rem;
    color: var(--text-color);
}

.searchInput {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 3rem !important;
    border: none !important;
    border-radius: 30px !important;
    font-size: 1rem;
    background-color: var(--secondary-color) !important;
    color: var(--text-color) !important;
}

.searchInput:focus {
    outline: none;
    border-color: var(--branding-purple) !important;
    box-shadow: 0 0 0 2px var(--light-focus-input-color) !important;
}

.searchIcon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
}

.backArrow {
    width: 36px;
    height: 36px;
}

.tabsContainer {
    display: flex;
    overflow-x: auto;
    margin-bottom: 1rem;
}

.tab {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    background: none;
    border: none;
    cursor: pointer;
    color: #6b7280;
    font-size: 0.875rem;
}

.activeTab {
    color: var(--search-tab-fg);
    border-bottom: 2px solid var(--search-tab-bg);
    background-color: var(--background-transparent);
    border-radius: 6px;
}

.resultsContainer {
    /* Add any specific styles for the results container */
}

.categorySection {
    margin-bottom: 1rem;
}

.titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.categoryTitle {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
}

.viewAllBtn {
    padding: 0.5rem 1rem;
    border-radius: 15px;
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    border: 1px solid var(--text-color);
    cursor: pointer;
    transition: all 1s ease;

}

.viewAllBtn:hover {
    background-color: var(--branding-purple);
    border: 1px solid var(--branding-purple);
}

.resultsList {
    list-style-type: none;
    padding: 0;
}

.resultItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
}

.message {
    text-align: center;
    color: #6b7280;
}

@media screen and (min-width: 778px) {
    .container {
        padding-top: 0px;
    }
}
