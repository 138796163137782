.BellBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.BellBtn span {
    font-size: 10px;
    color: #fff;
}

.badge {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--badge-accent);
    border-radius: 50%;
    height: 20px;
    width: auto;
    min-width: 14px;
    padding: 0 3px;
    font-size: 11px;
    font-weight: bold;

}
