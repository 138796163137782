.SearchBar {
    display: flex;
    flex-direction: column;
    justify-content: start;

    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    margin-top: 15px;
    position: relative;
    z-index: 2;
}



.UseCurrentLocationButton {
    width: 42px;
    height: 42px;
    position: absolute;
    bottom: 24px;
    left: 18px;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    color: var(--text-color);
    background-color: var(--secondary-color);
    cursor: pointer;
    border: 1px solid #999;

}

.UseCurrentLocationButton svg {
    color: var(--text-color);
}

.UseCurrentLocationButton svg path {
    fill: var(--text-color-secondary);
}

.SearchBarSortOptions ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    color: var(--white-text);
    padding: 0;
    width: 94%;
    margin: auto;
    gap: 0.75rem;
}

.ForYouSearchBarOptions ul {
    box-sizing: border-box;
    gap: 0.75rem;
}

.SearchBarSortOptions li, .SearchBarSortOptions button {
    cursor: pointer;
    height: 36px;
    background: var(--secondary-color);
    color: var(--text-color);
    border-radius: 9px;
    padding: 0;
    line-height: 1.13;
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    transition: color 0.25s;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    flex-grow: 1;
    width: 30%;
}


.SearchBarSortOptions li.active,
.SearchBarSortOptions button.active,
.SearchBarSortOptions li.active:hover {
    color: var(--white-text);
    background: var(--branding-purple);
}

.SearchBarSortOptions li svg, .SearchBarSortOptions button svg {
    margin-right: 6px;
    font-size: 18px;
}

.forYouButtons {
    flex-grow: 1;
    margin-bottom: 0.75rem;
}

.SearchBarFields {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
}

.SearchBarFields input {
    width: 33%;
    padding: 0.66rem 1rem;
    margin-right: 2.22rem;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 0.77rem;
    font-weight: 500;
    background-color: var(--text-color);
    color: var(--background-color);
}

.SearchBarFields input:last-child {
    margin-right: 0;
}

.SearchBarSubmit {
    text-align: center;
}

.SearchBarSubmit a {
    border-radius: 4px;
    padding: 0.57rem 1.5rem;
   background: var(--branding-blue);
    color: #ffffff;
    font-weight: 600;
    transition: background-color 0.5s;
}

.SearchBarSubmit a:hover {
    cursor: pointer;
    background-color: #a7874b;
}

.MapContainer {
    padding: 0.75rem 0;
    position: relative;
    height: 300px;
    width: 94%;
    margin: auto;
}

.MapContainer > div {
    border-radius: 15px;
}

.ForYouDesktop {
    display: none;
}

.LoadingSpinnerContainer {
    margin-left: 0; /* space between icon and spinner */
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.LoadingSpinner {
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-top: 2px solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Define the spinning animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@media screen and (min-width: 778px) {
    .SortOptionWrapper {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 9px;
        padding: 0.75rem 0;
        margin-left: 1.169rem;
        box-shadow: 0 0 11px #444;
        min-width: 183px;
    }
    .SearchBar {
        width: 50%;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        overflow-y: auto;
        margin-top: 0;
        padding-top: 0;
        display: flex;
        flex-direction: row;
    }

    .SearchBarSortOptions {
        margin-top: 147px;
        width: 30%;
        min-width: 162px;
    }

    .SearchBarSortOptions:first-child {
        position: absolute;
        z-index: 1;
    }

    .SearchBarSortOptions ul {
        flex-direction: column !important;
        width: 84%;
    }


    .SearchBarSortOptions li, .SearchBarSortOptions button {
        width: 100%;
        font-size: 15px;
        text-align: left;
        align-items: center;
        justify-content: start;
        padding-left: 9px;
        box-sizing: border-box;
    }

    .MapContainer {
        margin: initial;
        height: calc(100vh - 135px)
    }

    .MapAndFilters {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        align-items: center;
    }

    .ForYouSearchBarOptions {
        margin-top: 0.75rem;
        width: 100%;
    }

    .ForYouSearchBarOptions ul {

    }

    .forYouButtons {
        flex-grow: 1;
        margin-bottom: 0;
    }

    .ForYouDesktop {
        display: flex;
        flex-direction: column
    }

    .ForYouMobile {
        display: none;
    }
}

@media screen and (min-width: 1300px) {
    .SortOptionWrapper {
        margin-left: 1.8rem;
    }
}

@media screen and (min-width: 1600px) {
    .SortOptionWrapper {
        margin-left: 1.8rem;
    }
}

.loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 560px) {
    .SearchBar {

    }

    .SearchBarSortOptions ul {

    }

    .SearchBarFields {
        flex-direction: row;
        justify-content: space-evenly   ;
        align-items: center;
    }

    .SearchBarFields input {
        width: 33%;
        margin-right: 0;
        margin-bottom: 0;
    }
}
