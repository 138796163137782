.userSettingsMenu {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 18px 0 200px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 20px;
    padding-left: 15px;
}

.menuList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 3px;
}



.userSectionHelloWrap {
    height: 45px;
    display: flex;
    margin: 0 0 18px;
}

.userSectionHello {
    background-color: var(--branding-purple);
    color: var(--white-text);
    justify-content: space-between;
    height: 45px;
    display: flex;
    margin: 0 20px;
    width: 100%;
    border-radius: 9px;
    align-items: center;
}
.userSectionHello h2 {
    font-weight: normal;
    color: var(--white-text);
}

.deleteAccount a {
    background-color: var(--light-accent);
}

.menuItem {
    padding: 0 20px;
    margin-top: 6px;
}

.menuItem:last-child {
    border-bottom: none;
}

.menuLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 15px;
    color: var(--text-color);
    text-decoration: none;
    font-size: 0.9rem;
    background: var(--secondary-color);
    border-radius: 9px;
    height: 27px;
}

.menuLink:hover {
    background-color: var(--background-color);
}

.arrow {
    color: var(--text-color);
    vertical-align: middle;
    line-height: 0px;
}

.toggleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.section:first-child {

}

.section {


}

.bottomLinks {
    display: flex;
    flex-direction: row;
    gap: 9px;
    justify-content: space-evenly;
    padding: 0 20px;
}

.bottomLinks .menuItem {
    padding: 0;
    flex-grow: 1;
    flex-basis: max-content;
}
.bottomLinks .menuItem a {
    padding: 9px 12px;
}

.section:last-child {
    border-bottom: none;
}

.sectionHeader {
    font-size: .9rem;
    padding: 0 33px;
    color: var(--text-color-secondary);
}

.iconAndName {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: center;
}

.iconWrap {
    width: 33px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconAndName svg {
    font-size: 1.5rem;
    color: var(--settings-icon);
    width: 33px;
    height: 27px;
}

.contactPath {
    width: 27px;
    height: 27px;
    display: flex;
    margin-right: -3px;
    align-items: center;
}
.contactPath path {
    fill: var(--settings-icon);
}

.logoutIcon {
    color: var(--text-color) !important;
}

.toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.toggleCheckbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggleSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.toggleSlider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.toggleCheckbox:checked + .toggleSlider {
    background-color: #2196F3;
}

.toggleCheckbox:checked + .toggleSlider:before {
    transform: translateX(26px);
}

.logoutWrap {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 30px;
}
.logout {
    text-align: center;

    color: var(--text-color);
    height: 30px;
    width: 90%;
    max-width: 300px;
    border-radius: 12px;

}

.logoutText {
    text-align: center;
    width: 100%;
}

.superAdminSettingsMenu {
    max-width: 600px;
    margin: 30px 20px 0;
    padding: 15px 0;
    background-color: var(--secondary-color);
    position: relative;
    border-radius: 9px;
}

.superAdminMenuList {
    background-color: var(--secondary-color);
    padding: 0 10px 0;

}

.superAdminTextHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -24px;
    width: 100%;
}
.superAdminSection {
    margin-top: 21px;
}

.superAdminSectionHeader {
    padding: 0 9px;
    font-size: .69rem;
    margin-top: 9px;
}

.superAdminTitle {
    background-color: var(--black-text);
    border-radius: 9px;
    height: 30px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: normal;
    color: #fff;
    padding: 0 21px;
    display: flex;
    align-items: center;
}

.superAdminMenuItem {
    padding: 0 6px;

}

.superAdminMenuLink {
    background-color: var(--background-color);
}

.pluugBranding {
    display: inline;
}
.pluugBranding g:first-child {
    fill: var(--branding-green);
}
.pluugBranding g:last-child {
    fill: var(--branding-purple);
}
