.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    min-height: 100vh;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    padding-bottom: 120px; /* Add space for fixed button container */
}


.absBackground {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.639);
    top: 0;
    left: 0;
    z-index: 0;
}
.logoContainer {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 32px;
    z-index: 1;
    position: relative;
}

.logo {
    max-width: 300px;
    max-height: 100%;
}

.titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    z-index: 1;
}

.brandColor {
    color: var(--branding-purple);
}

.backButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-text);
}

.backButton:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
}

.title {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 6px;
    z-index: 1;
    position: relative;
    background: transparent !important;
}

.subtitle {
    margin: 0;
    font-size: 12px;
    color: var(--text-color-secondary);
    text-align: center;
    margin-bottom: 32px;
    z-index: 1;
    position: relative;
}

.dispensaryGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 32px;
    z-index: 1;
    position: relative;
}

.dispensaryButton {
    background-color: #5d6d7f;
    border: none;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logoImage {
    width: 100%;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-size: cover;
    margin-bottom: 8px;
}

.dispensaryButton img {
    width: 100%;
    max-width: 90px;
    max-height: 90px;
    border-radius: 50%;
    height: auto;
}

.dispensaryButton span {
    color: #e2e8f0;
    font-size: 12px;
}

.dispensaryButton.selected {
    background-color: var(--branding-purple-light);
}

.skipButton {
    background: none;
    border: none;
    color: #cbd5e1;
    font-size: 14px;
    margin-bottom: 16px;
    cursor: pointer;
    z-index: 1;
    position: relative;
}

.nextButton {
    width: 100%;
    max-width: 400px;
    padding: 15px 16px;
    background-color: var(--branding-purple);
    color: white;
    font-size: 15px;
    font-weight: bold;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
    z-index: 1;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nextButton:hover {
    background-color: var(--branding-purple-dark);
}

/* Keep all existing styles until the button styles */

.buttonContainer {
    position: fixed;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    z-index: 20;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
}

.buttonContainer.visible {
    transform: translateY(0);
}

.skipButton {
    background: rgba(80, 79, 79, 0.8);
    border-radius: 6px;
    padding: 0.5rem 0.9rem;
    border: none;
    color: #cbd5e1;
    font-size: 14px;
    margin-bottom: 16px;
    cursor: pointer;
    z-index: 1;
    position: relative;
}

.nextButton {
    width: 100%;
    max-width: 400px;
    padding: 15px 16px;
    background-color: var(--branding-purple);
    color: white;
    font-size: 15px;
    font-weight: bold;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
    z-index: 1;
    position: relative;
}


.nextButton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
